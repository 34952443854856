.big8{
    background-color: white; 
    height: 90vh !important; 
    margin-top: 40px; 
    width: 80%; 
    padding: 20px;
} 

@media (max-width: 700px) {
    .big8 {
       height: 110vh !important;
       width: 100%;
        
    }
  }