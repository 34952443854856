.flight-info {
 
   
    border: 1px solid rgba(128, 128, 128, 0.192);
    border-radius: 10px;
    margin-bottom: 20px; 

  }
  
  .flight-route {
    font-size: 16px;
   align-self: center;
   
  }
  
  .airport-code {
    text-transform: uppercase;
  }
  
  .flight-details {
    
    font-size: 14px;
  }
  
  /* Add more styles as needed to match the design */
  

  .vertical-line {
    border-left: 1px solid rgba(0, 0, 0, 0.24); /* Thickness and color of the line */
    height: 30px; /* Height of the line */ 
    margin: 5px;
}
.h-line {
   /* Thickness and color of the line */
  width: 90%; /* Height of the line */ 
  margin: 10px 0;
  height: 1px;
  background-color:rgba(0, 0, 0, 0.24);
}