@font-face{
  font-family: myFont;
  src: url('/public/assets/Fonts/TheSansArabic-Plain.otf');
}
body {
  background-color: #D6D9E4; 
  overflow-x: hidden !important;  
  font-family: myFont;
}

.OverviewReseat-payment-btn,.home-payment-btn{
  padding: 10px 20px;
  text-decoration: none;
  margin-top: 20px;
}
.home-payment-btn{
  background-color: #292929;
  color: white;
  border-radius: 10px;
  transition: .3s;
}

.home-payment-btn:hover{
  background-color:white ;
  color:#292929 ;
}
.OverviewReseat-payment-btn{
  background-color: #0d6efd;
  color: white;
  border-radius: 10px;
  transition: .3s;
}
.OverviewReseat-payment-btn:hover{
  background-color:white ;
  color:#292929 ;
}
.removeButton{
  color:#686868;
  text-decoration:underline;
  transition: .3s ease-in-out;
  cursor: pointer;
}
.removeButton:hover{
  color:#01537e;
  text-decoration:underline
}


