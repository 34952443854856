.checkbox{
    display: inline;
} 

.big9{
    position: absolute; 
    top: 0;
    right:0;
    background-color:white; 
    margin-top: 40px; 
    padding: 20px;
    width: 400px;
    border-radius: 5px;
    z-index: 1000;
} 

@media (max-width: 1200px) {
  .big9{
    position: relative; 
 
    background-color:white; 
    
    padding: 20px;
    width: 400px;
   
} 
} 

  .city{
    color: var(--Color-Text-Body-2, #1c1c1c);
font-feature-settings: 'calt' off, 'liga' off;



font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 150%; /* 24px */
  } 


  .arrow{
    color: #000C23; 
    font-weight: 800 !important;
  } 

  .button2{
    background-color: #FECD42;  
    text-decoration: none;
    border: none; 
    border-radius:20px; 
    width: 300px; 
    font-weight: 600; 
    color: #000C23; 
    padding: 5px; 
    z-index: 2000 !important;
  } 

  .button2:hover{
    scale:1.2;
  }

  