.flight-time-slider {
    display: flex;
    align-items: center;
    padding: 10px;
   
    border-radius: 5px;
    width: 80%; 
    transform: translate(100px); 
    margin: 20px;
    
  }
  
  .times {
    display: flex;
    justify-content: space-between;
    align-items: center; 
   
    width: 100%;
  }
  
  .time {
    margin: 0 5px; 
    font-size: 20px; 
    color: #FECD42; 
    font-weight: 700;
  }
  
  .slider-container {
    flex-grow: 1;
    margin: 0 10px;
  }
  
  .slider {
    -webkit-appearance: none;
    width: 100%;
    height: 4px;
    background: #ddd;
    outline: none;
    opacity: 0.7;
    transition: opacity .2s;
  }
  
  .slider:hover {
    opacity: 1;
  }
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #4CAF50;
    cursor: pointer;
  }
  
  .slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #4CAF50;
    cursor: pointer;
  } 

  .duration{
    transform: translate(-160px , 20px);
  } 

  .arrival-time{
    transform: translate(-110px);
  } 

  .stop{
    color: gray;
    font-weight: 600;
  }
  