.para2{
    color: var(--Color-Text-Body-2, #4A5970);
font-feature-settings: 'calt' off, 'liga' off;

/* Mulish/16/Medium */

font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 150%; /* 24px */
} 









  
  
      
      .input-group {
        margin-bottom: 20px;
      }
      
      .input-group label {
        display: block;
        font-weight: bold;
        margin-bottom: 5px;
      }
      
      .input-group input {
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
      }
      
      .checkout-button {
        width: 100%;
        padding: 10px 20px;
        background-color: #FECD42;
        border: none;
        border-radius: 4px;
        color: white;
        font-weight: bold;
        cursor: pointer;
      }
      

   
      .close-modal{
        position: absolute;
        right: -10px;
        top:  -10px;
        z-index: 10000;
      
      }
      .close-modal i:hover{
        color: #00409fa1;
        background-color: rgb(255, 140, 0);
       transition: .3s;
      }
      .close-modal i{
        position: absolute;
        right: -10px;
        top:  -10px;
        z-index: 10000;
        color: #00409ff0;
        background-color: rgb(255, 255, 255);
        border-radius: 30px;
        font-size: 25px;
        cursor: pointer;
      }
      .form-row {
        display: flex;
        gap: 20px;
      }
      .form-group {
       
        position: relative; 
        
      } 
      .form-group label {
        /* Styles for the label */
      }
      .form-group button { 
        position: absolute; 
        top:0px; 
        left: -15px;
        
       
        width: 50px; 
        height: 100%;
        border-radius: 50%; 
        cursor: pointer; 
        border: none;
        background:none;
        
        
       
        
      }  

      
      .form-group button i{
        color: gray;
      } 

      .form-group2 button { 
        position: absolute; 
        top:265px; 
        right:560px;
        width: 50px; 
        height: 100%;
        border-radius: 50%; 
        cursor: pointer; 
        border: none;
        background:none;
      }  

      .form-group2 button i{
        color: gray;
      } 

      .btn1{
        background-color: #FECD42; /* Sample button color */
       border: none;  
       border-radius:20PX;
        padding: 5PX;  
        width: 30%;
        
        
      } 

      .Link{
        text-decoration: none; 
        color: black;
      }
      

      .btn1 {
        display: inline-block;
        padding: 8px 15px; /* Adjust padding to your preference */
        background-color: #FECD42; /* Example button color */
        color: black; /* Text color */
        text-align: center;
        text-decoration: none; /* Removes underline from link */
        border: none; /* No border for a button-like appearance */
        cursor: pointer; /* Changes cursor to indicate clickable item */
        border-radius: 5px; /* Optional: rounded corners for the button */
        font-size: 16px; /* Adjust font size as needed */
      }
      
      .btn1:hover, .btn1:focus {
        background-color: #FECD42; /* Darker shade for hover/focus */
      }
      