.button{
    background-color: #FECD42; 
    border-radius: 20px; 
    border: none;
    width: 200px;
} 

.Link{
    text-decoration: none; 
    color: black;
}  

.buton1{
  border: none;
}


.button {
    display: inline-block;
    padding: 10px 20px; /* Adjust padding as needed */
    background-color: #FECD42; /* Bootstrap primary color for example */
    color: black; /* White text color */
    text-align: center;
    text-decoration: none; /* Removes underline from links */
    border: none; /* No border */
    cursor: pointer; /* Mouse cursor changes to pointer to indicate it's clickable */
    border-radius: 10px; /* Optional: adds rounded corners */
    font-size: 16px; /* Adjust font size as needed */
  }
  
  .button:hover, .button:focus {
    background-color: #FECD42; /* Slightly darker blue on hover/focus */
    color: #fff; /* Ensures text color remains white */
    text-decoration: none; /* Keeps the link from being underlined on hover/focus */
  }
   


