.text{
    color: var(--Color-Text-Body-2, #4A5970);
font-feature-settings: 'calt' off, 'liga' off;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 36px */
} 


.Conformation{
    transform: translate(800px ,-1500px);
    z-index:1001 !important;
} 



@media (max-width: 700px) {
    .Conformation {
        transform: translate(200px);
        
    }
  } 


  .background{
    position: fixed;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Dim the background */
    z-index: 1000; /* Make sure it's on top of other elements */
    display: flex;
    justify-content: center;
    align-items: center;
  } 

  .form233{
    
    z-index: 2000 !important;
    
  
    
  } 


  .modal-close-button {
    position: absolute;
    top: 4.1rem;
    right: 21.5rem;
    border: none;
    background: none;
    font-size: 1.5rem;
    cursor: pointer;
    z-index: 2000;
  } 

  .para2{
    color: var(--Color-Text-Body-2, #4A5970);
font-feature-settings: 'calt' off, 'liga' off;

/* Mulish/16/Medium */

font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 150%; /* 24px */
} 










 
    .flight-form {
        max-width: 700px;
        margin: 10px 20px;
        padding: 30px;
        background-color: white;
        box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        border-radius: 10px;
      }
      
      .input-group {
        margin-bottom: 20px;
      }
      
      .input-group label {
        
        font-weight: bold;
        margin-bottom: 5px;
      }
      
      .input-group input {
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
      }
      
      .checkout-button {
        width: 100%;
        padding: 10px 20px;
        background-color: #FECD42;
        border: none;
        border-radius: 4px;
        color: white;
        font-weight: bold;
        cursor: pointer;
      }
  

      .form-row {
        display: flex;
        gap: 20px;
      }
   
     
  .date-icon{
    position: relative;
    display: flex;

    width: 100%;
  }
  .react-datepicker-wrapper{
    width: 100%;
  }
  .date-icon input{
    width: 100%;
  }
 
  .date-icon i{
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  } 
     



      .btn1{
        background-color: #FECD42; /* Sample button color */
       border: none;  
       border-radius:20PX;
        padding: 5PX;  
        width: 30%;
        
        
      } 

      .Link{
        text-decoration: none; 
        color: black;
      }
      

      .btn1 {
        display: inline-block;
        padding: 8px 15px; /* Adjust padding to your preference */
        background-color: #FECD42; /* Example button color */
        color: black; /* Text color */
        text-align: center;
        text-decoration: none; /* Removes underline from link */
        border: none; /* No border for a button-like appearance */
        cursor: pointer; /* Changes cursor to indicate clickable item */
        border-radius: 5px; /* Optional: rounded corners for the button */
        font-size: 16px; /* Adjust font size as needed */
      }
      
      .btn1:hover, .btn1:focus {
        background-color: #FECD42; /* Darker shade for hover/focus */
      }
      