.big4{
    background-color: white; 
    height: 150vh; 
    margin-top: 40px; 
    width: 80%; 
    padding: 20px;
} 

@media (max-width: 700px) {
    .big4 {
       height: 180vh !important;
        
    }
    .image-wrapper{
        width: 100% !important;
    }
  }