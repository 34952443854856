/* .big2{
    background-color: white; 
    height: 110vh; 
    margin-top: 40px; 
    width: 80%; 
    padding: 20px;
}  */

@media (max-width: 700px) {
    .big2 {
        /* height: 130vh !important;  */
        width: 100% !important;
    }
  } 

  .btn:hover{
    scale:1.05;
  }