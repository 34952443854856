/* Seat.css */
.seat {
  width: 20px;
  height: 20px;
  margin: 2px;
  cursor: pointer; 
  z-index: 1000;
  transition: .3s;
} 



.seat:hover{
  scale: 1.5;
}

.seat-row {
  display: flex;
  /* Add styles for row spacing and alignment */
} 



  