.para{
    color: var(--Color-Text-Body-2, #000000);
font-feature-settings: 'calt' off, 'liga' off;

/* Mulish/16/Medium */

font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 150%; /* 24px */
} 




/* @keyframes Form { 
   from{
    transform:  translate(0px, 200px);
   } 

   to{
    transform: translate(0px 200px) ;
   }
    
} */ 




    .Save{
        color: black; 
        text-decoration: none;
    }
    .PassengerInfo{
       width: 900px;
       border-radius: 30px;
    }
