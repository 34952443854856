.Footer{
    
    background-color:#637289; 
    

} 

.text-footer{
    color: #292929; 
    font-size: 15px; 
}
.footer-text-list{
    list-style: none;
  
}
.footer-link{
    text-decoration: none;
    color: #292929;
    transition: .3s;

}
.footer-link:hover{
    color: #FACE3E;
}
.footer-icon{
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    cursor: pointer;
    transition: .3s;
    color: #292929; 
    text-decoration: none;
    font-size: 18px;
}
.footer-icon:hover{
    background-color:#a6c0e7ad; 
    color: rgb(65, 65, 65); 
}
.background-wave{
  background-position: center; 
  background-repeat: repeat; 
  background-size: cover; 
}