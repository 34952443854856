.counter-pointer{
    background-color:#ffffff;
    height: 40px;
    width: 40px;
    border:1px solid #FECD42;
    color: #FECD42;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    transition: .4s;
  }
  .counter-pointer:hover{
    background-color:#FECD42;
    height: 40px;
    width: 40px;
    border:1px solid #FECD42;
    color: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;  
  } 

 
  .btn-ok{
    padding: 10px 20px;
    border: none;
    background-color: #273771;
    border-radius: 10px;
    color: #ffffff;
    transition: .5s;
    font-weight: bold;
  }
  .btn-ok:hover{
    background-color: #FACE3E;
    color: #273771;
  }
  .trash-delete i{
    color: rgb(145, 0, 0);
    cursor: pointer;
    padding: 10px;
    transition: .3s;
    border-radius: 10px;
  }
  .trash-delete i:hover{
    color: rgb(255, 255, 255);
    background-color: rgb(170, 7, 7);
  }
  .pen-fancy i{
    color: rgb(9, 80, 123);
    cursor: pointer;
    padding: 10px;
    transition: .3s;
    border-radius: 10px;
  }
  .pen-fancy i:hover{
    color: rgb(255, 255, 255);
    background-color: rgb(7, 135, 170);
  
  }