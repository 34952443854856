/* SeatRow.css */
.seat-row {
  display: flex;
  justify-content: center; 
  margin: 0px;  
  transform: translate(-20px);
}



.row-number2{

  font-weight: 400; 
  font-size: 10px;
  margin-left: -20px;
  color: rgb(163, 163, 163);
  
}
.row-number {
  font-weight: 400; 
  font-size: 10px;
  left: 20%;
  margin-right: 5px; 
  color: rgb(163, 163, 163);
   
  /* border-right: 1px solid #ccc; */
} 

.seat-row{
  margin: 10px;
}


@media only screen and (max-width: 600px) {
  .seat-row{
   margin: 10px; 
  } 
  } 


.seat-row {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px; /* Space between rows */
}


.seat-section {
  display: flex;
  justify-content: space-between;
  margin-right: 20px; /* Space between seat sections */
}

/* You might need to add more styles for proper spacing, alignment, and to display the aisle gaps */


  