.airplane-body {
  display: flex;
  flex-direction: column;
  align-items: center; /* Correctly center align-items for consistent layout */
  justify-content: center;
  position: relative;
  width: 100%;
}

.airplane-image {
  width: 89%; /* Responsive width */
  height: auto; /* Maintain aspect ratio */
  position: relative;
  padding: 0%; 
  
}

@media (width: 600px) {
  /* .seats {
      right: 30px;
      margin: 40px;
      padding: 30px;
  } 
  .airplane-image{
    display: none !important;
  } */
}
.Airplane-contain{
  position: relative;
  width: 300px ;
  height: 1500px;
  margin-top: 200px;
  display: flex;
}

.seats {
  position: absolute;
  top: 0; 
  left: -10px; /* Adjust this value to center the seats horizontally within the image */
  width: 300px; /* Adjust width to fit within the body of the airplane */
  height: 1200px; /* Adjust height to cover only the seating area in the image */
  z-index: 2; /* Make sure seats are above the image visually */
  display: flex;
  flex-direction: column;
} 
.Airplane-image{
  position: absolute;
  top:0;
  left:-20px;
  z-index: 1;
}
.Airplane-image img{
 width: 1000px;
 height: 1500px;
 top:-210px;
 left: -320px;
}







/* Add this to ensure it aligns with your rows */
.seat-header-label:nth-child(1) { margin-left: [LEFT_MARGIN]px; }
.seat-header-label:last-child { margin-right: [RIGHT_MARGIN]px; }

    
    
    
    

