.Cover{
    background-image: url("../../../public/assets/cover.png");
  width: 100%;

   
    background-repeat: no-repeat;
  
   
    
} 

/* Additional styles */



