.titleCard{
  color:#292929; 
  text-decoration: none; 
  font-size: 22px;
  font-weight: bold;
  margin-left: 90px;
} 

.card-text{
    font-size: 14px; 
} 

.card {
    height: 450px !important;
    width: 400px;
} 

.getActionCard{
    text-decoration: none; 
    font-size: 15px;
    background-color: #FECD42;
    border-radius: 5px;
    color: #292929; 
    font-weight: bold;
    position: relative;
    width:150px;
    justify-content: center;
    height: 40px;
} 
.getActionCard a{
    text-decoration: none; 
    font-size: 16px;
} 
.hover-getActionCard{
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    border-radius: 5px;
    background-color:#273771 ;
    display: flex;
   transition: .5s;
   z-index: 1;

}
.getActionCard:hover .hover-getActionCard{ 
    width: 100%;
     ;
} 
.getActionCard a{ 
    color: #292929; 
}
.getActionCard:hover a{ 
    color: #ffffff; 
    transition: .2s;
} 
.hover-getActionCard:hover .getActionCard a{
    color: #ffffff; 
    transition: .2s;
   
} 
.hover-text-link{
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.message-show-details{
    width: 300px;
    padding: 5px;
    background-color: #292929;
    color:white ;
    border-radius: 5px;
    position: absolute;
    right: 10px;
    bottom:25px;
    line-height: 1.6;
    font-size: 13px;
    text-align: start;
    display: none;
}
.message-container:hover .message-show-details{
    display: flex;
    transition: 3s;
}