/* Nav.css */
.navbar { 
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10; 
  }
  
  .language-dropdown{
    cursor: pointer;
  }
  
  
