.Services{
    color: #292929;
    text-align: center;
    font-feature-settings: 'calt' off, 'liga' off;
    
    /* Mulish/32/Bold */
    
    font-size: 32px;
    font-style: normal;

    line-height: 150%; /* 48px */
} 

.ServicesPara{
    color: #292929;
text-align: center;

font-size: 17px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 36px */
} 
.loader-div{
  min-height: 85vh;
  display: flex;
  align-items: center;
}
.loader {
  border: 6px solid #f3f3f3; /* Light grey */
  border-top: 6px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  margin: 20px auto;
}
.main-loader {

  animation: spin 1s linear infinite;
}


@keyframes spin {
  0% { transform: rotate(360deg); }
  100% { transform: rotate(0deg); }
}


@media (max-width: 768px) {
    .row > .col {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .payment-sammary{
    color: black;
    text-decoration: underline;
    transition: .3s;
    cursor: pointer;
  }
  .payment-sammary:hover{
    color: #3498db;
    text-decoration: underline;
  }
  .button-checkout{
    padding:7px 40px ;
    background-color:#FECD42;
    border: 1px solid #FECD42;
    margin: 0;
    border-radius: 20px;
    font-weight: bold;
    font-size: 14px;
    transition: .3s;
  }
  .button-checkout:hover{
    background-color:#ffff;
    border :1px solid #FECD42;
    color: #FECD42;
  }
  .change-flight{
    cursor: pointer;
    text-decoration: underline;
    color: #3498db;

  }