
  



  
  
  
  
  
  .flight img {
    width: 100%; 
   
  }
  
  .flight-info {
    color: gray;
    font-size: 15px;
    margin-top: 10px;
  }
  

  
 
  .login-background{
    background: linear-gradient(90deg in oklab, #273771, #D6D9E4,#FACE3E);

  }
  .login-container{
      border-radius: 60px;
    
  }
  .login-right-background{
  background-image: url("/public/assets/signIn.png");
  background-repeat: no-repeat;
  background-size:cover;
  border-radius: 50px;
  min-height: 400px;
  position: relative;
  color: #ffffff;
  }
  .cover-login{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.495);
    border-radius: 50px;
    z-index: 1;
    display: flex; 
    flex-direction: column;
    gap: 30px;
    justify-content: center;
    align-items: center;
    padding: 5px;
    text-align: center;
  }
  .cover-login p{
    font-size: 18px;
  }
  .cover-login h2{
    color: #FACE3E;
  }
  .Login-button{
    margin-top: 30px;
    width: 100%;
    background-color: #FACE3E;
    color: #292929 ;
    border: none ;
    border-radius: 20px;
     padding: 10px 0;
     font-weight: bold;
     transition: .5s;
  }
  .Login-button:hover{
    background-color: #273771;
    color: #ffffff ;
   
  }
 .fa-user-login{
  position: absolute;
  top:0px
  left 0px
 }