.big5{
    background-color: white; 
    height: 90vh; 
    margin-top: 40px; 
    width: 80%; 
    padding: 20px;
}  


@media (max-width: 700px) {
    .big5 {
       height: 110vh !important;
        
    }
  }