.Cover{
    background-image: url("../../../public/assets/cover.png");
  width: 100%;

    /* background-size: cover;
    background-position: center;  */
   
    
}  

.form{
  transform: translate(0px ,50px);
}









.vertical-line {
  border-left: 1px solid rgba(0, 0, 0, 0.24); /* Thickness and color of the line */
  height: 30px; /* Height of the line */ 
  margin: 5px;
}